<template>
  <BCard>
    <h3 class="mb-2 text-10">
      Data Komerce
    </h3>
    <BRow class="gap-[8px]">
      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Tanggal bergabung <span class="text-danger">*</span>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Tanggal Bergabung"
            :rules="'required'"
          >
            <FlatPickr
              :value="joinDate"
              class="form-control"
              :config="{
                altInput: true,
                altFormat: 'j F Y',
                dateFormat: 'Y-m-d',
              }"
              placeholder="Tanggal, Bulan, Tahun"
              @input="$emit('update:joinDate', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>

      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Sektor Bisnis <span class="text-danger">*</span>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Sektor Bisnis"
            rules="required"
          >
            <VSelect
              :value="businessSectorId"
              label="partner_category_name"
              :reduce="option => option.id"
              :options="listSectorbusiness"
              placeholder="Pilih Sektor Binis"
              @input="$emit('update:businessSectorId', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>

      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Tipe Bisnis <span class="text-danger">*</span>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Tipe Bisnis"
            rules="required"
          >
            <VSelect
              :value="businessTypeId"
              label="name"
              :reduce="option => option.id"
              :options="listTypeBussiness"
              placeholder="Pilih Tipe Bisnis"
              @input="$emit('update:businessTypeId', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>

      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Nama Brand <span class="text-danger">*</span>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Nama Brand"
            rules="required"
          >
            <BFormInput
              :value="brandName"
              type="text"
              :formatter="capitalize"
              placeholder="Ketikkan nama brand kamu"
              :state="errors.length > 0 ? false : null"
              @input="$emit('update:brandName', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>

      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Jumlah Tim <span class="text-danger">*</span>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Jumlah Tim"
            rules="required"
          >
            <VSelect
              :value="teamMembers"
              :reduce="option => option.value"
              :options="teamMemberOptions"
              :filterable="true"
              placeholder="Jumlah tim kamu"
              @input="$emit('update:teamMembers', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>

      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Referensi <span class="text-danger">*</span>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Referensi"
            rules="required"
          >
            <VSelect
              :value="reference"
              :reduce="option => option.value"
              :options="referenceOptions"
              :filterable="true"
              placeholder="Pilih referensi kamu"
              @input="$emit('update:reference', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>

      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Bonus
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Bonus"
          >
            <BFormInput
              :value="bonus"
              type="text"
              :formatter="capitalize"
              placeholder="Masukkan bonus kamu"
              :state="errors.length > 0 ? false : null"
              @input="$emit('update:bonus', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>

      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Produk <span class="text-danger">*</span>
          </template>
          <div class="d-flex flex-wrap gap-[12px] mb-1">
            <div
              v-for="(e, i) in products"
              :key="i"
              class="flex items-center px-1 py-[5px] gap-1 rounded-full bg-[#FCD4BE] text-black"
            >
              <span>{{ e.name }}</span>
              <span
                class="k-close text-[25px] cursor-pointer"
                @click="removeProduct(e)"
              />
            </div>
          </div>
          <BButton
            v-if="!formProductActive"
            variant="outline-primary"
            class="ml-auto px-3"
            @click="toggleForm()"
          >
            + Tambah
          </BButton>
          <template v-else>
            <ValidationProvider
              #default="{ errors }"
              name="Produk"
              rules="required|max:50"
            >
              <BFormInput
                v-model="product"
                type="text"
                placeholder="Tambah Produk"
                @keyup.enter="addProduct"
                @input="checkProductExist()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="productExist"
                class="text-danger"
              >Kamu tidak bisa memasukkan produk yang sama</small>
            </ValidationProvider>
          </template>
        </BFormGroup>
      </BCol>

    </BRow>
  </BCard>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { capitalize } from '@/libs/helpers'
import FlatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    ValidationProvider,
    FlatPickr,
    VSelect,
  },
  props: {
    joinDate: {
      type: String,
      default: null,
    },
    businessSectorId: {
      type: [Number, String],
      default: null,
    },
    businessTypeId: {
      type: [Number, String],
      default: null,
    },
    brandName: {
      type: String,
      default: null,
    },
    teamMembers: {
      type: String,
      default: '',
    },
    reference: {
      type: String,
      default: '',
    },
    bonus: {
      type: String,
      default: '',
    },
    listSectorbusiness: {
      type: Array,
      default: () => [],
    },
    listTypeBussiness: {
      type: Array,
      default: () => [],
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      capitalize,
      showPassword: false,
      showConfirmPassword: false,
      productExist: false,
      teamMemberOptions: [
        { label: '0 - 4 orang', value: '0 - 4' },
        { label: '5 - 19 orang', value: '5 - 19' },
        { label: '20 - 99 orang', value: '20 - 99' },
        { label: '> 100 orang', value: '> 100' },
      ],
      referenceOptions: [
        { label: 'Media Sosial', value: 'Media Sosial' },
        { label: 'Google search', value: 'Mesin Pencari Google' },
        { label: 'Rekomendasi Teman', value: 'Rekomendasi Teman' },
        { label: 'Acara / Event', value: 'Acara' },
      ],
      formProductActive: false,
      product: '',
    }
  },
  mounted() {
    if (this.products && this.products.length) this.formProductActive = false
    else this.formProductActive = true
  },
  methods: {
    toggleForm() {
      this.formProductActive = !this.formProductActive
    },
    addProduct() {
      const temp = {
        name: this.product,
        id: 0,
      }
      this.$emit('update:products', [...this.products, temp])
      this.product = ''
      this.formProductActive = false
    },
    removeProduct(val) {
      const temp = this.products.filter(e => e !== val)
      this.$emit('update:products', temp)
    },
    checkProductExist() {
      this.productExist = this.products.some(p => p.name.toLowerCase() === this.product.toLowerCase())
    },
  },
}
</script>
