<template>
  <BCard>
    <h3 class="mb-2 text-10">
      Informasi Dasar
    </h3>
    <BRow class="flex-col-reverse md:flex-row">
      <BCol md="8">
        <BRow class="gap-[8px]">
          <BCol md="12">
            <BFormGroup
              label-cols-md="4"
              label-class="font-medium text-7 text-black"
            >
              <template #label>
                Email <span class="text-danger">*</span>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <BFormInput
                  :value="email"
                  type="email"
                  placeholder="Ketik email kamu"
                  :state="
                    errors.length > 0 ? false : null
                  "
                  @input="$emit('update:email', $event)"
                  @blur="$emit('check:email', $event)"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>

          <BCol md="12">
            <BFormGroup
              label-cols-md="4"
              label-class="font-medium text-7 text-black"
            >
              <template #label>
                Nama Lengkap <span class="text-danger">*</span>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Nama Lengkap"
                rules="required|min:3|max:50|alpha_spaces"
              >
                <BFormInput
                  :value="fullname"
                  :formatter="capitalize"
                  placeholder="Ketik nama lengkap kamu"
                  @input="$emit('update:fullname', $event)"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>

          <BCol md="12">
            <BFormGroup
              label-cols-md="4"
              label-class="font-medium text-7 text-black"
            >
              <template #label>
                Nama Bisnis <span class="text-danger">*</span>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Nama Bisnis"
              >
                <BFormInput
                  :value="businessName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Ketikkan nama bisnis kamu"
                  @input="$emit('update:businessName', $event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>

          <BCol md="12">
            <BFormGroup
              label-cols-md="4"
              label-class="font-medium text-7 text-black"
            >
              <template #label>
                Nomor HP <span class="text-danger">*</span>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Nomor Hp"
                rules="required|min:9|max:15"
              >
                <cleave
                  :value="phone"
                  class="form-control"
                  :options="options.phone"
                  placeholder="Ketik nomor hp kamu"
                  @input="$emit('update:phone', $event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>

          <BCol md="12">
            <BFormGroup
              label-cols-md="4"
              label-class="font-medium text-7 text-black"
            >
              <template #label>
                Username <span class="text-danger">*</span>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Username"
                :rules="{ regex: /^[a-zA-Z0-9 ]*$/, required: true, max: 25 }"
              >
                <b-form-input
                  :value="username"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Ketik username kamu"
                  @input="$emit('update:username', $event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>

          <BCol md="12">
            <BFormGroup
              label-cols-md="4"
              label-class="font-medium text-7 text-black"
            >
              <template #label>
                Jenis Kelamin <span class="text-danger">*</span>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Jenis kelamin"
                rules="required"
              >
                <b-form-radio-group
                  v-model="genderDefault"
                  class="mt-50"
                  :options="genderOptions"
                  @input="$emit('update:gender', $event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>

          <BCol md="12">
            <BFormGroup
              label-cols-md="4"
              label-class="font-medium text-7 text-black"
            >
              <template #label>
                NIK <span class="text-danger">*</span>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="NIK"
                rules="required|numeric|min:16|max:16"
              >
                <b-form-input
                  :value="nik"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Ketik NIK kamu minimal 16 karakter"
                  @input="$emit('update:nik', $event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>

          <BCol md="12">
            <BFormGroup
              label-cols-md="4"
              label-class="font-medium text-7 text-black"
            >
              <template #label>
                Lokasi <span class="text-danger">*</span>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Lokasi"
                rules="required"
              >
                <v-select
                  :value="subdistrictId"
                  :reduce="option => option.subdistrict_id"
                  :options="listSubdistrict"
                  :state="
                    errors.length > 0
                      ? false
                      : null
                  "
                  placeholder="Desa, Kecamatan, Kab., Provinsi, Kode Pos"
                  @input="$emit('update:subdistrictId', $event)"
                  @search="(search) => search.length > 0 ? $emit('search:subdistrictId', search) : ''"
                >
                  <template
                    #selected-option-container="{ option }"
                  >
                    <div class="vs__selected">
                      {{ `${option.subdistrict_name}, ${option.district_name}, ${option.regency_name}, ${option.province_name}` }}
                    </div>
                  </template>
                </v-select>
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>

          <BCol md="12">
            <BFormGroup
              label-cols-md="4"
              label-class="font-medium text-7 text-black"
            >
              <template #label>
                Alamat Lengkap <span class="text-danger">*</span>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Alamat"
                rules="required|min:5"
              >
                <b-form-textarea
                  :value="address"
                  placeholder="Jl. Cinta Damai No.666, RT.003/RW.007 "
                  :state="
                    errors.length > 0 ? false : null
                  "
                  @input="$emit('update:address', $event)"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>

          <BCol md="12">
            <BFormGroup
              label-cols-md="4"
              label-class="font-medium text-7 text-black"
            >
              <template #label>
                MoU
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="mou"
                :rules="{ regex: regexUrl }"
              >
                <b-form-input
                  :value="mou"
                  placeholder="Masukkan link dokumen"
                  @input="$emit('update:mou', $event)"
                />
                <small class="text-danger">{{
                  errors[0] ? 'Hanya bisa input link' : null
                }}</small>
              </ValidationProvider>
            </BFormGroup>
          </BCol>
        </BRow>
      </BCol>
      <BCol
        md="4"
        class="d-flex flex-col justify-start items-center gap-[32px]"
      >
        <div class="profile-preview d-flex justify-center">
          <span
            v-if="!imageFile && !imageInitialFile"
            class="k-user-square text-[230px] text-[#C2C2C2]"
          />
          <div
            v-else
            class="img-preview d-flex items-center justify-center relative"
          >
            <img
              v-if="imageFile || imageInitialFile"
              :src="imageFile ? fileUrl(imageFile) : imageInitialFile"
              alt="preview"
              class="image-preview"
            >
            <img
              v-else
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/image.svg"
              alt="no-image"
            >
            <div
              class="absolute __overlay items-center justify-center"
            >
              <button
                class="d-flex justify-center items-center bg-gray-50 rounded px-2 py-[10px] gap-10"
                @click="$bvModal.show('modalPreview')"
              >
                <span class="k-eye" />
                Lihat Foto
              </button>
            </div>
          </div>
        </div>
        <div class="select-file d-flex">
          <div
            v-if="!imageFile"
            class="w-full"
          >
            Upload foto kamu
          </div>
          <div
            v-else
            class="w-full"
          >
            {{ imgName.length > 15 ? `${imgName.substr(0, 15)} ...   ` : imgName }}
          </div>
          <div>Upload</div>
          <input
            type="file"
            name="photo profile"
            class="custom-file"
            @change="uploadFile($event)"
          >
          <span
            v-if="imageFile"
            class="k-close text-danger absolute z-10 text-[32px] top-[6px] right-[80px] cursor-pointer"
            @click="removeFile()"
          />
        </div>
      </BCol>
    </BRow>
    <ModalPreview :image-url="imageFile ? fileUrl(imageFile) : imageInitialFile" />
  </BCard>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { capitalize, regexUrl } from '@/libs/helpers'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.id'
import VSelect from 'vue-select'
import ModalPreview from '../ModalPreview.vue'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    ValidationProvider,
    Cleave,
    VSelect,
    ModalPreview,
  },
  props: {
    email: {
      type: String,
      default: '',
    },
    fullname: {
      type: String,
      default: '',
    },
    businessName: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
    username: {
      type: String,
      default: '',
    },
    gender: {
      type: String,
      default: 'L',
    },
    nik: {
      type: String,
      default: '',
    },
    subdistrictId: {
      type: [Number, Object],
      default: null,
    },
    address: {
      type: String,
      default: '',
    },
    mou: {
      type: String,
      default: '',
    },
    imageFile: {
      type: File,
      default: null,
    },
    imageInitialFile: {
      type: String,
      default: null,
    },
    listSubdistrict: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visibleBusiness: true,
      visiblePic: true,
      imgName: '',
      capitalize,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'ID',
        },
      },
      genderOptions: [
        { text: 'Laki-laki', value: 'L' },
        { text: 'Perempuan', value: 'P' },
      ],
      regexUrl,
    }
  },
  computed: {
    genderDefault: {
      get() {
        return this.gender
      },
      set() {
        return this.gender
      },
    },
  },
  methods: {
    uploadFile(e) {
      const img = e.target.value
      const fileExtension = img.substr((img.lastIndexOf('.') + 1))
      if (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png') {
        // eslint-disable-next-line no-alert
        alert('Hanya dapat upload file dengan ekstensi JPG, PNG dan JPEG')
      } else {
        this.$emit('update:imageFile', e.target.files[0])
        this.imgName = img.split('\\').pop()
      }
    },
    fileUrl: file => URL.createObjectURL(file),
    removeFile() {
      this.imgName = ''
      this.$emit('update:imageFile', null)
    },
  },
}
</script>

<style lang="scss" scoped>
  .profile-preview {
    max-width: 275px;
    height: 275px;
    border-radius: 8px;
    background: #F8F8F8;
    box-shadow: 0px 0px 16.5px 0px rgba(0, 0, 0, 0.15);
  }
  .select-file {
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    position: relative;
  }
  .select-file > div {
    padding: 10px 14px 10px 14px;
    cursor: pointer;
  }
  .select-file > div:nth-child(1) {
    color: #c2c2c2;
    padding-right: 30px;
  }
  .select-file > div:nth-child(2) {
    border-left: 1px solid #e2e2e2;
  }
  .custom-file {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
.img-preview {
  height: 100%;
  width: 100%;
  background-color: #f4f4f4;
  border: 1px solid #828282;
  border-radius: 16px;
  overflow: hidden;
}
.image-preview {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.__overlay {
  display: none;
  background: #33333340;
  border-radius: 16px;
  border: 1px solid #828282;
  transition: ease-in-out .5;
}
.img-preview:hover > .__overlay {
  display: flex;
  height: 100%;
  width: 100%;
}
</style>
